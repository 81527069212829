import { shallowMount } from "@/../tests/vueTestUtils.js";
import { createLocalVue } from "@vue/test-utils";
import OrderingService from "../OrderingService.vue";
import { orderingServices, states } from "@/utils/enums/ordering-services";
import { Store } from "vuex-mock-store";

let wrapper;
let store;

const localVue = createLocalVue();

const getters = {
  "businesses/getData": [
    {
      id: "business_id",
      name: "Business 1",
      url: "https://bussiness/url/1",
      logo: "https://path/business/logo/1",
      country: "AE",
      currency: {
        code: "AED",
        symbol: "AED",
      },
    },
    {
      id: "b_2",
      name: "Business 2",
      url: "https://bussiness/url/2",
      logo: "https://path/business/logo/2",
      country: "US",
      currency: {
        code: "USD",
        symbol: "US$",
      },
    },
  ],
};

beforeEach(() => {
  store = new Store({
    getters,
  });

  wrapper = shallowMount(OrderingService, {
    propsData: {
      businessId: "business_id",
      outletId: "outlet_id",
      orderingServiceSlug: "delivery",
      tenantId: "tenant_id",
    },
    localVue,
    data: () => ({
      outlet: {
        url: "https://outlet/url",
        deliveryFee: 1000,
        minimumBasket: 3000,
        orderingServices: [
          {
            state: states.ENABLED,
            orderingServiceEnum: orderingServices.DELIVERY,
            paymentMethods: [],
            acceptsAsapOrders: true,
            acceptsPreOrders: true,
            acceptsSameDayPreOrders: true,
            minLeadingTime: 5,
            maxDaysAdvance: 2,
            slotLengthMin: 1,
            orderingServiceSettings: [
              {
                preparationMinutes: 15,
                estimatedCompletionMinutes: 30,
              },
            ],
          },
        ],
      },
    }),
    mocks: {
      $store: store,
      $apollo: {
        queries: {
          outlet: {
            loading: false,
            refresh: jest.fn(),
          },
        },
      },
    },
  });
});

afterEach(() => {
  wrapper.destroy();
});

describe("Action", () => {
  it("should refetch service settings when copied from another ordering settings", () => {
    wrapper.vm.refetch();

    expect(wrapper.vm.$apollo.queries.outlet.refresh).toHaveBeenCalled();
  });
});

describe("Rendering", () => {
  it("should display order scheduling options for delivery", async () => {
    wrapper.setData({
      outlet: {
        ...wrapper.vm.outlet,
        ...{
          orderingServices: [
            {
              state: states.ENABLED,
              orderingServiceEnum: orderingServices.DELIVERY,
              paymentMethods: [],
              acceptsAsapOrders: true,
              acceptsPreOrders: true,
              acceptsSameDayPreOrders: true,
              minLeadingTime: 60,
              maxDaysAdvance: 2,
              slotLengthMin: 30,
              orderingServiceSettings: [
                {
                  preparationMinutes: 15,
                  estimatedCompletionMinutes: 30,
                },
              ],
            },
          ],
        },
      },
    });
    await wrapper.vm.$nextTick();
    expect(wrapper.find("[data-test=order-scheduling]").exists()).toBeTruthy();
  });

  it("should not display order scheduling options for dine in", async () => {
    wrapper.setData({
      outlet: {
        ...wrapper.vm.outlet,
        ...{
          orderingServices: [
            {
              state: states.ENABLED,
              orderingServiceEnum: orderingServices.DINE_IN,
              paymentMethods: [],
              acceptsAsapOrders: true,
              acceptsPreOrders: true,
              acceptsSameDayPreOrders: true,
              minLeadingTime: 60,
              maxDaysAdvance: 2,
              slotLengthMin: 30,
              orderingServiceSettings: [
                {
                  preparationMinutes: 15,
                  estimatedCompletionMinutes: 30,
                },
              ],
            },
          ],
        },
      },
    });
    await wrapper.vm.$nextTick();
    expect(wrapper.find("[data-test=order-scheduling]").exists()).toBeFalsy();
  });
});
